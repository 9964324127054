import React, { useState } from "react"
import ChimpForm from "../../../components/chimpForm"
import goldenWalletLogo from "../../../images/promotions/goldenwallet/golden-wallet-sweepstakes-logo.svg"
import btButton from "../../../images/promotions/goldenwallet/bt-button.svg"
import utButton from "../../../images/promotions/goldenwallet/ut-button.svg"
import { Link } from "gatsby"
import winnerImg1 from "../../../images/promotions/goldenwallet/golden-wallet-winner-week-1.jpg"
import winnerImg2 from "../../../images/promotions/goldenwallet/golden-wallet-winner-week-2.jpg"
import winnerImg3 from "../../../images/promotions/goldenwallet/golden-wallet-winner-week-3.jpg"
import winnerImg4 from "../../../images/promotions/goldenwallet/golden-wallet-winner-week-4.jpg"
import winnerImgGP from "../../../images/promotions/goldenwallet/golden-wallet-winner-grand-prize.jpg"


const GoldenWallet = ({ pageContext: {text} }) => {

	const [showEmail, setShowEmail] = useState(false)
	const [walletAddress, setWalletAddress] = useState('')

	function clickShowEmail() {
		setShowEmail(true)
	}

	return (
		<div className="container d-flex flex-column align-items-center justify-content-start text-white text-center position-relative overflow-hidden" style={{minHeight: `1200px`, fontFamily:`Inter`}}>
			<div className="mb-4 d-flex flex-column flex-md-row align-items-center justify-content-center">
				<img src={goldenWalletLogo} className="img-fluid px-3" alt="BitTorrent Golden Wallet logo"></img>
			</div>
			<div className="goldenwallet-62 font-weight-bold">
				Grand Prize <span className="goldenwallet-primary">10,000,000 BTT </span>
			</div>
			<div className="goldenwallet-32 font-weight-normal mb-3">
				(at least $50,000 USD)
			</div>
			<div className="goldenwallet-40 font-weight-bold mb-3">
				Four Weekly Prizes <span className="goldenwallet-primary">1,000,000 BTT </span>
			</div>
			<div className="goldenwallet-20">
				Sweepstakes ended October 11th, 4pm.
			</div>

			<div className="my-5">
				<p className="goldenwallet-62 text-center" style={{fontWeight: 600}}>Golden Wallet Winner Stories</p>
				<div className="goldenwallet-winner-container d-flex flex-wrap justify-content-center">
					<WinnerCard title="Week 1" img={winnerImg1} blogLink="/blog/2021/10/07/golden-wallet-sweepstakes-winner-2/"/>
					<WinnerCard title="Week 2" img={winnerImg2} blogLink="/blog/2021/10/07/golden-wallet-sweepstakes-winner-3/"/>
					<WinnerCard title="Week 3" img={winnerImg3} blogLink="/blog/2021/10/07/golden-wallet-sweepstakes-winner/"/>
					<WinnerCard title="Week 4" img={winnerImg4} blogLink="/blog/2021/10/11/golden-wallet-sweepstakes-winner-4/"/>
					<WinnerCard title="Grand Prize!" img={winnerImgGP} className="goldenwallet-primary font-weight-bold" blogLink="/blog/2021/11/01/golden-wallet-sweepstakes-grand-prize-winner/"/>
				</div>
			</div>

			<div className="goldenwallet-16">
				<Link id="goldenwallet-rulesanddetails" className="goldenwallet-primary" to="/promotions/goldenwallet/rules/">
					<u>Sweepstakes rules and details</u>
				</Link>
			</div>
		</div>
	)
}

const WinnerCard = ({title, img, className, blogLink}) => {
	return (
		<div className={`mx-3 my-3 ${className}`}>
			<div className="text-decoration-none mb-2">{title}</div>
			<Link className={`d-block goldenwallet-20`} to={blogLink} >
				<div className="goldenwallet-winner-card">
					<img src={img}/>
				</div>
			</Link>	
		</div>
	)
}

export default GoldenWallet